/* eslint-disable no-async-promise-executor */
// import axios from '@/utils/axios'
import { xhr } from '@/utils/axios'

export const getAll = async () => {
  const response = await xhr.get(`/organisation/unit/`)

  return response
}

export const getOne = async id => {
  const response = await xhr.get(`/organisation/unit/${id}`)

  return response
}

export const createOne = async ({ parent_id, name, state_is_open, uuid }) => {
  const formData = new FormData()

  formData.append('parent_id', parent_id)
  formData.append('name', name)
  formData.append('state_is_open', state_is_open)
  formData.append('uuid', uuid)

  const response = await xhr.post(`/organisation/unit/`, formData)

  return response
}

export const updateOne = async ({ id, name, state_is_open }) => {
  const formData = new FormData()

  formData.append('name', name)
  formData.append('state_is_open', state_is_open)

  const response = await xhr.patch(`/organisation/unit/${id}/`, formData)

  return response
}

export const deleteOne = async ({ id }) => {
  const response = await xhr.delete(`/organisation/unit/${id}/`)

  return response
}
