<template>
    <div>
      <div
        class="flex flex-col w-full lg:flex-row bg-white border border-gray-300 rounded-sm min-h-176 space-x-2"
      >
        <div
          class="border-r w-full lg:w-2/5"
          :class="{
            'hidden lg:flex': $route.name === 'organisation-roleprofile',
          }"
        >
          <div class="flex flex-col min-h-full">
            <h3 class="w-full p-4 font-semibold text-base">
              Organisationseinheit
            </h3>
            <template v-if="emptyTree">
              <tree :node.sync="units" :permission="breadcrumbs.permission" />
            </template>
            <template v-else>
              <div class="p-2 min-h-96 flex">
                <div
                  class="flex flex-col min-h-full w-full items-center justify-center space-y-2"
                >
                  <p class="text-center">
                    Definieren Sie Ihre Organisationseinheiten
                  </p>
                  <div>
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 128 128"
                      style="enable-background: new 0 0 128 128"
                      xml:space="preserve"
                      class="w-16"
                    >
                      <g>
                        <path
                          class="st0"
                          d="M59.5,36H32H4.5C3.7,36,3,35.3,3,34.5v-14C3,19.7,3.7,19,4.5,19h55c0.8,0,1.5,0.7,1.5,1.5v14 C61,35.3,60.3,36,59.5,36z"
                        />
                        <path
                          class="st1"
                          d="M123.5,75h-55c-0.8,0-1.5-0.7-1.5-1.5v-14c0-0.8,0.7-1.5,1.5-1.5h55c0.8,0,1.5,0.7,1.5,1.5v14 C125,74.3,124.3,75,123.5,75z"
                        />
                        <path
                          class="st0"
                          d="M123.5,114h-55c-0.8,0-1.5-0.7-1.5-1.5v-14c0-0.8,0.7-1.5,1.5-1.5h55c0.8,0,1.5,0.7,1.5,1.5v14 C125,113.3,124.3,114,123.5,114z"
                        />
                        <polyline
                          class="st2"
                          points="32.5,35.5 32.5,66.5 67,66.5 	"
                        />
                        <path
                          class="st2"
                          d="M32.5,66.5V104c0,0.8,0.7,1.5,1.5,1.5h33"
                        />
                      </g>
                    </svg>
                  </div>
                  <button
                    @click="appendNode"
                    class="rounded text-white bg-green-500 p-2"
                  >
                    Organisationseinheit erstellen
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="w-full lg:w-4/5">
          <div class="md:px-2 text-blue-600">
            <breadcrumbs
              :title="title"
              :action="actions"
              :upload="upload"
              :permission="permission"
            />
          </div>
          <router-view @breadcrumbs="updateActionbar" />
        </div>
      </div>
      <div
        class="fixed inset-0 bg-white z-50 w-full"
        :class="{ hidden: !showChart }"
      >
        <div class="flex flex-col items-start relative">
          <v-select
            @input="filterroot($event)"
            :options="this.filterRootOption"
            v-model="filteroption"
            :clearable="true"
            placeholder="Organisationseinheit filtern"
            class="style-chooser mb-2 border ml-2 mt-2 rounded p-1 text-xs focus:outline-none bg-transparent v-select-custom"
          />
          <a
            href="#"
            v-show="showClose"
            @click.prevent="showChart = false"
            class="absolute right-2 top-2 z-30"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
  
          <button
            class="p-2 m-2 rounded border bg-blue-dark text-white focus:outline-none hover:bg-blue-900"
            id="org-tree-preview"
          >
            PDF exportieren
          </button>
        </div>
  
        <org-tree
          :show="showChart"
          ref="orgtree"
          @show-close="payload => (showClose = payload)"
        />
      </div>
    </div>
  </template>
  
  <script>
  import _ from 'lodash'
  import OrgTree from '@/components/OrgTree'
  import Tree from '@/components/Tree'
  import { getUnits } from '@/services/organization'
  export default {
    name: 'organization',
    props: ['value'],
    components: {
      Tree,
      OrgTree,
    },
    data() {
      return {
        filterRootOption: [],
        filteroption: null,
        showClose: true,
        direction: 'top',
        units: {},
        permission: {
          module: 'Unternehmen',
          subModule: 'Organisation',
          childModule: 'Organisation',
          operation: 'edit',
          options: ['All'],
        },
        breadcrumbs: {
          title: 'Meine Organisationseinheit',
          actions: {
            title: 'Rollenprofil erstellen',
            component: 'new-role',
            resource: '/organisation/roleprofile',
            maxWidth: 'w-full md:w-1/3',
            event: 'realod-units',
          },
          permission: {
            module: 'Unternehmen',
            subModule: 'Organisation',
            childModule: 'Organisation',
            operation: 'edit',
            options: ['All'],
          },
          upload: {
            title: 'Organigramm anzeigen',
            event: 'organisation-chart',
            global: true,
          },
        },
        actions: null,
        title: null,
        upload: null,
        showChart: false,
        show_units: true,
        show_roleprofiles: true,
        show_employes: true,
      }
    },
    mounted() {
      this.init()
      this.$bus.$off('reload-tree')
      this.$bus.$off('organisation-chart')
      this.$bus.$on('reload-tree', this.init)
      this.$bus.$on('organisation-chart', () => (this.showChart = true))
      const units = this.updateUUIDs([...this.profile.units])
      this.updateProfile({
        ...this.profile,
        units,
      })
    },
    methods: {
      init() {
        if (this.currentNode) {
          this.breadcrumbs = {
            ...this.breadcrumbs,
            parentId: this.currentNode.id,
          }
          this.$emit('breadcrumbs', this.breadcrumbs)
        }
        getUnits().then(response => {
          if (response.status === 200) {
            this.units = _.first(this.recursiveUpdate(response.data.results))
          }
        })
        getUnits('name').then(response => {
          if (response.status === 200) {
            this.filterRootOption = response.data.results
            var res = this.filterRootOption.map(unit => ({
              label: unit.name,
              value: unit.id,
            }))
            this.filterRootOption = res
            // [ { "label": "Erste Ebene", "value": 1 }, { "label": "Zweite Ebene", "value": 2 }, { "label": "Dritte Ebene", "value": 3 }, { "label": "Vierte Ebene", "value": 4 } ]
            // [ { "label": "Board of Directors", "value": 1} ]
            // {label: 'Erste Ebene', value: 1}, {label: 'Zweite Ebene', value: 2}, {label: 'Dritte Ebene', value: 3} ,{label: 'Vierte Ebene', value: 4}
          }
        })
      },
      appendNode() {
        const uuid = `unit-${this.timestamp()}`
        this.units = {
          uuid,
          _key: uuid,
          name: 'Organisation',
          parent_id: 0,
          state_is_open: true,
          is_disabled: false,
          roleprofiles: [],
          children: [],
        }
        this.updateCurrentNode(this.units)
      },
      updateActionbar(payload) {
        this.actions = payload?.actions
        this.title = payload?.title
        this.upload = payload.upload
        this.permission = payload.permission
      },
      updateTreeFilters(filter) {
        this.treeFilters = _.xor(this.treeFilters, [filter])
      },
      filterroot(event) {
        this.$refs.orgtree.init(event)
      },
    },
    computed: {
      emptyTree() {
        return _.values(this.units).length
      },
      dataset() {
        return this.currentNode && this.currentNode.id
          ? { ...this.currentNode, identifier: 'id' }
          : { ...this.units, identifier: 'id' }
      },
      myUnits() {
        return _.first(this.recursiveUpdate(this.profile.units))
      },
    },
  }
  </script>


  <style>

  .v-select-custom{
    position: absolute;
    width: 200px;
    left: 10rem;

  }
  .st0 {
    fill: #005ca2;
  }
  .st1 {
    fill: #3db6ff;
  }
  .st2 {
    fill: none;
    stroke: #000000;
    stroke-miterlimit: 10;
  }
  .tree-container {
    left: 50%;
    overflow: visible !important;
  }
  </style>