<template>
  <div id="tree" ref="tree"></div>
</template>

<script>
import _ from 'lodash'
import { xhr } from '@/utils/axios'
import OrgChart from '@balkangraph/orgchart.js'
// import OrgChart from '@balkangraph/orgchart.js'
export default {
  name: 'org-tree',
  props: { show: { default: false } },
  data() {
    return {
      nodes: [],
      tags: {},
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(option) {
      const params = option && option.value ? `?root=${option.value}` : ''
      xhr.get(`/organisation/chart/${params}`).then(response => {
        this.nodes = _.map(response.data.nodes, node =>
          node.avatar
            ? node
            : {
                ...node,
                avatar: !node.tags
                  ? `https://ui-avatars.com/api/?name=${
                      node.initial
                        ? node.initial.split('').join(' ')
                        : node.name
                    }`
                  : '',
              }
        )
        _.each(
          _.map(_.compact(_.map(this.nodes, node => node.tags)), _.head),
          key => {
            this.tags[key] = {
              subTreeConfig: {
                columns: 2,
              },
            }
          }
        )
        this.tags = {
          group: {
            template: 'group',
          },
          ...this.tags,
        }
        this.chart = null
        this.mytree(this.$refs.tree, this.nodes, this.tags)
        this.ExpandFit()
      })
    },
    ExpandFit() {
      setTimeout(() => {
        this.chart.expand(null, 'all')
        this.chart.fit()
      }, 1000)
    },
    pdf() {
      OrgChart.pdfPrevUI.show(this.chart, {
        format: 'A4',
        padding: 50,
        margin: [70, 40, 50, 40],
        header: `<img src="${this.profile.org.logo}" width="50" style="position: absolute; right: 0;" />`,
        footer: '',
        
      })
      const vm = this
      this.$emit('show-close', false)
      const el = document.querySelector('#boc-ppdf-btns > h1')
      const saveBtn = document.querySelector('#boc-prev-save')
      const cancleBtn = document.querySelector('#boc-prev-cancel')
      const layout = document.querySelector('#boc-ppdf-layout')
      const scale = document.querySelector('label[for="boc-scale"]')
      const header = document.querySelector('#boc-ppdf-header')
      const parentHeader = header.closest('div')
      parentHeader.style.display = 'none'
      const footer = document.querySelector('#boc-ppdf-footer')
      const parentFooter = footer.parentElement.closest('div')
      parentFooter.style.display = 'none'
      const margin = document.querySelector('#boc-ppdf-margin')
      const parentMargin = margin.parentElement.closest('div')
      parentMargin.style.display = 'none'
      el.innerHTML = 'PDF Vorschau'
      saveBtn.innerHTML = 'Speichern'
      cancleBtn.innerHTML = 'Abbrechen'
      layout.firstChild.innerHTML = 'Hochformat'
      layout.lastChild.innerHTML = 'Querformat'
      scale.innerHTML = 'Skalierung'
      cancleBtn.addEventListener('click', () => {
        vm.$emit('show-close', true)
      })
      saveBtn.addEventListener('click', () => {
        vm.$emit('show-close', true)
      })
    },
    mytree: function(domEl, x, t) {
      OrgChart.A4w = 794;
      OrgChart.A4h = 1123;
      OrgChart.templates.cool = Object.assign({}, OrgChart.templates.ana)
      OrgChart.templates.cool.menuButton = `<button class="absolute left-2 top-2 px-2 py-0.5 rounded border bg-blue-dark text-white focus:outline-none hover:bg-blue-900" id="org-tree-preview">PDF exportieren</button>`
      OrgChart.templates.group.link =
        '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="M{xa},{ya} {xb},{yb} {xc},{yc} L{xd},{yd}" />'
      OrgChart.templates.group.nodeMenuButton = ''
      OrgChart.templates.group.min = Object.assign({}, OrgChart.templates.group)

      OrgChart.templates.group.min.img_0 = ''
      OrgChart.templates.cool.field_2 =
        '<text class="field_2" style="font-size: 12px;" fill="#FFFFFF" x="195" y="70" text-anchor="middle">Pensum: {val}%</text>'
      OrgChart.scroll.smooth = 6
      OrgChart.scroll.speed = 6
      OrgChart.SEARCH_PLACEHOLDER = 'Suchen'
      this.chart = new OrgChart(domEl, {
        nodes: x,
        enableSearch: true,
        nodeMouseClick: null,
        state: {
          name: 'StateForMyTree',
          readFromLocalStorage: true,
          writeToLocalStorage: true,
        },
        scaleInitial: OrgChart.match.boundary,
        layout: OrgChart.tree,
        mouseScrool: OrgChart.none,
        align: OrgChart.ORIENTATION,
        toolbar: {
          layout: true,
          zoom: true,
          fit: true,
          expandAll: true,
        },
        nodeBinding: {
          description: 'description',
          field_0: 'name',
          field_1: 'roleprofile',
          field_2: 'workload',
          img_0: 'avatar',
        },
        // menu: {
        //   export_pdf: {
        //     text: 'PDF exportieren',
        //     icon: OrgChart.icon.pdf(24, 24, '#7A7A7A'),
        //     onClick: this.pdf,
        //   },
        // },
        tags: t,
        template: 'cool',
      })
      this.chart.on('init', function(sender) {
        sender.toolbarUI.showLayout()
      })
      // this.chart.RES.IT_IS_LONELY_HERE_LINK = ''
      this.chart.on('field', function(sender, args) {
        
        // limit the roleprofile name to 20 chars 
        if (args.name == 'roleprofile') {
          if(args.value){
          if (args.value.length > 20) {
            args.value = args.value.substring(0, 20) + '...';
          }
          }
        }

        if (args.node.min) {
          if (args.name == 'img') {
            var count =
              args.node.stChildrenIds.length > 5
                ? 5
                : args.node.stChildrenIds.length
            var x = args.node.w / 2 - (count * 32) / 2
            for (var i = 0; i < count; i++) {
              var data = sender.get(args.node.stChildrenIds[i])
              args.value +=
                '<image xlink:href="' +
                data.img +
                '" x="' +
                (x + i * 32) +
                '" y="45" width="32" height="32" ></image>'
            }
          }
        }
      })
      this.chart.on('exportstart', function(sender, args){
        args.styles += document.getElementById('myStyles').outerHTML;
      });
      this.chart.on('click', function(sender, args) {
        if (args.node.tags.indexOf('group') != -1) {
          if (args.node.min) {
            sender.maximize(args.node.id)
          } else {
            sender.minimize(args.node.id)
          }
        }
        return false
      })
    },
  },
  watch: {
    show: {
      handler: function(n) {
        const vm = this
        if (n) {
          setTimeout(() => {
            const btn = document.getElementById('org-tree-preview')
            btn.addEventListener('click', () => {
              vm.pdf()
            })
          }, 1000)
        }
      },
    },
    immediate: true,
  },
}
</script>
<style>
div[data-ctrl-menu] {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  left: 10px !important;
  top: 0 !important;
  transform: scale(0.75);
}
.boc-chart-menu div {
  display: flex;
  left: 50px !important;
}
#boc-header {
  right: 40px !important;
}
.boc-light div:first-of-type {
  /* display: flex; */
}
.boc-light {
  display: flex;
  max-height: calc( 100% - 55px );
}
#tree > div:nth-of-type(2) {
  display: flex;
}
</style>