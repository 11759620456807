<template>
  <div class="w-full flex">
    <ul class="flex flex-col w-full overflow-hidden">
      <li
        class="group hover:bg-blue-50 pl-4 pr-2"
        :class="{
          parent_li: node.is_parent,
          'bg-blue-200': currentNode && currentNode.uuid === node.uuid,
        }"
        @click="currentUnit(node)"
      >
        <div class="flex w-full group py-1 space-x-1" :class="`pl-${childLevel}`">
          <a
            href=""
            @click.prevent="node.state_is_open = !node.state_is_open"
            class="inline-block py-1 transform transition delay-50 duration-50 ease-in-out"
            :class="{ 'rotate-90': node.state_is_open }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
          <input
            type="text"
            v-model="node.name"
            :disabled="node.is_disabled"
            @keypress.enter="save(node)"
            @blur="save(node)"
            class="px-2 h-6 border-0 w-4/5 group-hover:bg-blue-50"
            :id="node.uuid"
          />
          <div
            v-if="
              permission &&
                can(
                  permission.module,
                  permission.subModule,
                  permission.childModule,
                  permission.operation,
                  permission.options
                )
            "
            class="opacity-0 group-hover:opacity-100"
          >
            <ul class="flex space-x-2 my-1">
              <li>
                <a href="#" @click.prevent="editFocus(node)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  @click.prevent="
                    addNode(node)
                    setFocus(node)
                  "
                  class="border border-black border-dashed rounded-sm block"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clip-rule="evenodd"
                    /></svg
                ></a>
              </li>
              <li>
                <a href="#" @click.prevent="deleteNode(node)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 text-red-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <ul v-if="node.state_is_open && node.children.length">
        <tree
          v-for="(item, index) in node.children"
          :node="item"
          :child-level="childLevel ? childLevel + 2 : 2"
          :permission="permission"
          :key="`node-${index}`"
        ></tree>
      </ul>
    </ul>
  </div>
</template>

<script>
import _ from 'lodash'
import { me } from '@/services/auth'
import { getOne, createOne, updateOne, deleteOne } from '@/services/unit'

export default {
  name: 'tree',
  props: ['node', 'childLevel', 'permission'],
  components: {},
  data() {
    return {
      stamp: this.timestamp(),
      deleteStatus: false,
      saved: false,
    }
  },
  mounted() {
    this.setFocus(this.node)

    setTimeout(() => {
      if (
        this.currentNode &&
        this.currentNode.id !== undefined &&
        this.$route.name !== 'organisation-roleprofile' &&
        this.$route.name !== 'redirect'
      ) {
        this.$router.push(`/organisation/unit/${this.currentNode.id}/`)
      }
    }, 500)
  },
  methods: {
    addNode(node) {
      const name = `Organisationseinheit ${node.children.length + 1}`

      this.stamp = this.timestamp()

      node.state_is_open = true

      this.saved = false

      node.children.push({
        name: name,
        parent_id: node.id,
        is_parent: true,
        is_disabled: false,
        children: [],
        roleprofiles: [],
        state_is_open: true,
        uuid: `unit-${this.stamp}`,
      })

      setTimeout(() => {
        this.updateCurrentNode(_.last(node.children))
      }, 500)
    },
    editFocus(node) {
      const uuid = node.uuid
      let el = null
      node.is_disabled = false

      this.saved = false

      this.$nextTick(() => {
        el = document.querySelector(`#${uuid}`)
        el.focus()
      })
    },
    setFocus(node) {
      const uuid = node.children && node.children.length ? _.last(node.children).uuid : node.uuid
      let el = null

      setTimeout(() => {
        el = document.querySelector(`#${uuid}`)
        el.focus()
      }, 300)

      // setTimeout(() => {
      //   el.select()
      // }, 300)
    },
    currentUnit(node) {
      this.updateCurrentNode(node)
      if (!this.deleteStatus && node.id) this.$router.push(`/organisation/unit/${node.id}`)
      this.deleteStatus = false
    },
    save(node) {
      if (!this.saved) {
        if (node.id) {
          updateOne(node)
            .then(() => {
              this.$bus.$emit('reload-tree')

              this.saved = true
              node.is_disabled = true
              return me()
            })
            .then(response => {
              const units = this.updateUUIDs([...response.data.units])

              this.updateProfile({
                ...this.profile,
                units,
              })
            })
        } else {
          createOne(node)
            .then(response => {
              this.$bus.$emit('reload-tree')

              this.saved = true
              node.is_disabled = true

              setTimeout(() => {
                this.updateCurrentNode({
                  ...response.data.data,
                  children: [],
                  roleprofiles: [],
                })
              }, 500)

              return me()
            })
            .then(response => {
              const units = this.updateUUIDs([...response.data.units])

              this.updateProfile({
                ...this.profile,
                units,
              })
            })
        }
      }
    },
    deleteNode(node) {
      this.deleteStatus = true
      if (this.currentNode.parent_id) {
        getOne(this.currentNode.parent_id).then(response => {
          this.updateCurrentNode({ ...response.data })

          deleteOne(node)
            .then(response => {
              if (response.status === 200) {
                this.$bus.$emit('reload-tree')
                // this.$toast.success(response.data.message)
              }
            })
            .catch(err => {
              this.$toast.error(err.response.data.message)
            })
        })
      } else this.updateCurrentNode({})
    },
  },
  watch: {
    currentNode: {
      handler: function(n) {
        if (n && n.id) this.$router.push(`/organisation/unit/${n.id}`)
      },
      deep: true,
    },
  },
}
</script>
